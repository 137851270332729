import PropTypes from 'prop-types';
import styles from './ProductImageCardList.module.scss';
import ProductImageCard from '../ProductImageCard';
import Button from '../Button';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';

const ProductImageCardList = ({
    title,
    link,
    items,
    isDownloadLink,
    modifiers,
}) => {
    const classes = classNames(
        styles['ProductImageCardList'],
        modifiers.map(
            (modifier) =>
                styles['ProductImageCardList--' + ucFirst(modifier)] + ' '
        )
    );
    return (
        <div className={classes}>
            <div className={styles['ProductImageCardList__Container']}>
                <div className={styles['ProductImageCardList__Row']}>
                    <h2 className={styles['ProductImageCardList__Title']}>
                        {title}
                    </h2>
                    {link && (
                        <div
                            className={classNames(
                                styles['ProductImageCardList__Link'],
                                styles['ProductImageCardList__Link--Desktop']
                            )}>
                            <Button
                                link={link}
                                type={'primary'}
                                icon={'arrow'}
                            />
                        </div>
                    )}
                </div>

                {items && (
                    <div className={styles['ProductImageCardList__List']}>
                        {items.map((item, i) => (
                            <div
                                key={i}
                                className={classNames(
                                    styles['ProductImageCardList__Item']
                                )}>
                                <ProductImageCard
                                    {...item}
                                    isDownloadLink={isDownloadLink}
                                />
                            </div>
                        ))}
                    </div>
                )}
                <div
                    className={classNames(
                        styles['ProductImageCardList__Link'],
                        styles['ProductImageCardList__Link--Mobile']
                    )}>
                    {link && (
                        <Button link={link} type={'primary'} icon={'arrow'} />
                    )}
                </div>
            </div>
        </div>
    );
};

ProductImageCardList.propTypes = { modifiers: PropTypes.array };

ProductImageCardList.defaultProps = { modifiers: [] };

export default ProductImageCardList;
