// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './ProductImageCard.module.scss';
import NextImage from 'next/image';

const ProductImageCard = ({
    title,
    description,
    link,
    productImage,
    productLogoImage,
    isDownloadLink,
}) => {
    const [hasLoadedProductImage, setHasLoadedProductImage] = useState(false);
    const [hasLoadedLogoImage, setHasLoadedLogoImage] = useState(false);
    return (
        <a
            aria-label={title}
            href={isDownloadLink ? `${link.href}downloads?scrollToTabs=1` : link.href}
            className={styles['ProductImageCard']}>
            <div className={styles['ProductImageCard__Images']}>
                {productLogoImage.url && (
                    <div
                        className={
                            styles['ProductImageCard__ProductLogoImageWrapper']
                        }>
                        <NextImage
                            alt={`${title} logo`}
                            className={classNames(
                                styles['ProductImageCard__ProductLogoImage'],
                                {
                                    [styles[
                                        'ProductImageCard__ProductLogoImage--Loaded'
                                    ]]: hasLoadedLogoImage,
                                }
                            )}
                            src={productLogoImage.url}
                            layout="fill"
                            objectFit="contain"
                            priority={true}
                            onLoadingComplete={() =>
                                setHasLoadedLogoImage(true)
                            }
                        />
                    </div>
                )}

                {productImage.url && (
                    <div
                        className={
                            styles['ProductImageCard__ProductImageWrapper']
                        }>
                        <NextImage
                            alt={`${title}`}
                            className={classNames(
                                styles['ProductImageCard__ProductImage'],
                                {
                                    [styles[
                                        'ProductImageCard__ProductImage--Loaded'
                                    ]]: hasLoadedProductImage,
                                }
                            )}
                            src={productImage.url}
                            layout="fill"
                            objectFit="contain"
                            priority={true}
                            onLoadingComplete={() =>
                                setHasLoadedProductImage(true)
                            }
                        />
                    </div>
                )}
            </div>
            <div className={styles['ProductImageCard__Content']}>
                <p className={styles['ProductImageCard__Description']}>
                    {description}
                </p>
            </div>
        </a>
    );
};

ProductImageCard.propTypes = {};

ProductImageCard.defaultProps = {
    link: {},
    productImage: {},
    productLogoImage: {},
    productLogoImageLight: {},
    title: '',
};

export default ProductImageCard;
