import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import { ucFirst } from '../../utils/caseconverters';

const Button = ({
    type,
    buttonType,
    text,
    link,
    icon,
    iconSize,
    iconDirection,
    disabled,
    modifiers,
    onClick,
}) => {
    const hasLink = link && link.href;
    text = text || link.title || link.text;

    const classes = classNames(
        styles['Button'],
        styles['Button--' + ucFirst(type)],
        modifiers.map(
            (modifier) => styles['Button--' + ucFirst(modifier)] + ' '
        )
    );

    const innerContent = (
        <span
            className={classNames(styles['Button__Inner'], {
                [styles['Button__Inner--' + ucFirst(iconDirection)]]:
                    iconDirection,
            })}>
            <span className={type === "onlyIcon" ? "sr-only" : styles['Button__Text']}>{text}</span>
            {!!icon && <Icon type={icon} size={iconSize} />}
        </span>
    );

    const bars = (
        <>
            <span
                className={classNames(
                    styles['Button__Bar'],
                    styles['Button__Bar--1']
                )}></span>
            <span
                className={classNames(
                    styles['Button__Bar'],
                    styles['Button__Bar--2']
                )}></span>
            <span
                className={classNames(
                    styles['Button__Bar'],
                    styles['Button__Bar--3']
                )}></span>
            <span
                className={classNames(
                    styles['Button__Bar'],
                    styles['Button__Bar--4']
                )}></span>
        </>
    );

    const buttonAttributes = {
        className: classes,
        onClick: onClick,
    };

    const buttonText = icon ? innerContent : text;

    return hasLink ? (
        <a {...buttonAttributes} href={link.href} target={link.target}>
            <span className={styles['Button__Text']}>{buttonText}</span>
            {bars}
        </a>
    ) : (
        <button {...buttonAttributes} type={buttonType} disabled={disabled}>
            <span className={styles['Button__Text']}>{buttonText}</span>
            {bars}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'accordionFooter', "playerListFooter", "onlyIcon"]),
    buttonType: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    srText: PropTypes.string,
    icon: PropTypes.string,
    iconDirection: PropTypes.string,
    iconSize: PropTypes.string,
    disabled: PropTypes.bool,
    modifiers: PropTypes.array,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    type: 'primary',
    buttonType: '',
    text: '',
    href: null,
    link: {},
    srText: '',
    icon: null,
    iconDirection: '',
    iconSize: '',
    disabled: false,
    modifiers: [],
};

export default Button;
